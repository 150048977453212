import React from 'react';
import { useTheme } from '@mui/material/styles';
import Main from 'layouts/Main';
import Container from 'components/Container';
import Contact from 'components/Contact';

const ContactPageSidebarMap = () => {
  const theme = useTheme();

  return (
    <Main>
      <Container>
        <Contact />
      </Container>
    </Main>
  );
};

export default ContactPageSidebarMap;
